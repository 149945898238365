import React, { useState } from "react"
import Layout from "../layout/layout"
import ReactHtmlParser from "react-html-parser"
import styled from "styled-components"
import Fade from "react-reveal/Fade"
import HomestayVideo from "../video/Homestay.mp4"
import Video from "../components/Video/LargeVideo"
import ButtonCardItem from "../components/ButtonCards/NewTabButton"
import { CardGrid, CardWrapper } from "../components/ButtonCards/CardElements"
import Logo from "../images/logo.png"
import { educationBanner } from "../images"
import Accordion from "../components/Accordions/WorkWithUsHomestayFAQ"
import LargeVideo from "../components/Video/LargeVideo"
import { EducationTestimony, InsertedTestimony } from "../components/Testimonials"
import { EducationPopup } from "../components/FormPopup"
import { CentreWrapper, RealButton } from "../components/ButtonElement"
import SEO from "../components/seo"
import { graphql } from "gatsby"


const VideoContainer = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
`

const ApplicationWrapper = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
`

const sidebar = [
  {
    to: "CareersAdvice",
    title: "Careers Advice",
  },
  {
    to: "CourseApplications",
    title: "Course Applications",
  },
  {
    to: "PrivateTuition",
    title: "Private Tuition",
  },
  {
    to: "Cultural",
    title: "Cultural & Etiquette training",
  },
  {
    to: "HolidayCamps",
    title: "Holiday Camps",
  },
  {
    to: "Familiarisation",
    title: "Familiarisation Trips",
  },
  {
    to: "StudyTours",
    title: "Study Tours",
  },
  {
    to: "InternationalTeacherRecruitment",
    title: "International teacher recruitment",
  },
]

const FadeH1 = ({ children }) => {
  return (
    <div style={{ position: "relative", textAlign: "center" }}>
      <Fade>
        <h1>{children}</h1>
      </Fade>
      <br />
    </div>
  )
}

const Section = styled.div`
  margin: 40px 230px;
  max-width: 100%;

  @media (max-width: 1200px) {
    margin: 40px 100px;
  }

  @media (max-width: 900px) {
    margin: 40px 50px;
  }

  @media (max-width: 740px) {
    margin: 9px;
  }
`

export default function EducationServices({ data }) {
  const html = data.allWpPage.edges
  const Applications = ReactHtmlParser(html[0].node.content)
  const CareersService = ReactHtmlParser(html[1].node.content)
  const FamiliarisationTrips = ReactHtmlParser(html[2].node.content)
  const HolidayCamp = ReactHtmlParser(html[3].node.content)
  const InternationalTeacherRecruitment = ReactHtmlParser(html[4].node.content)
  const PrivateTuition = ReactHtmlParser(html[5].node.content)
  const StudyTours = ReactHtmlParser(html[6].node.content)
  const Header = ReactHtmlParser(html[7].node.content)
  const Cultural = ReactHtmlParser(html[8].node.content)
  const [formType, setFormType] = useState("")
  return (
    <div>
      <Layout
        sidebar={sidebar}
        pageTitle="Education Services"
        background={educationBanner}
        pageDescription="Award-winning advice and services to access the best of British education"
      >
        <SEO title="Education Services | UK Education | Oxbridge | Careers | Career Advice" description="Our bespoke education careers service helps truly ambitious and determined students identify and fully prepare for their chosen career path."/>
        <Section id="CareersAdvice">
          <FadeH1>Careers Advice</FadeH1>
          {CareersService.map((data, index) => {
            return (
              <Fade>
                <div className="no-image-data">{data}</div>
              </Fade>
            )
          })}
          <CentreWrapper>
            <RealButton
              type="button"
              data-bs-toggle="modal"
              data-bs-target="#exampleModal1"
              onClick={() => setFormType("Careers Advice")}
            >
              Contact us
            </RealButton>
          </CentreWrapper>
          <EducationPopup formType={formType} />
        </Section>
        <Section id="CourseApplications">
          <FadeH1>Course Applications</FadeH1>
          {Applications.map((data, index) => {
            return (
              <Fade>
                <div className="no-image-data">{data}</div>
              </Fade>
            )
          })}
          <CentreWrapper>
            <RealButton
              type="button"
              data-bs-toggle="modal"
              data-bs-target="#exampleModal1"
              onClick={() => setFormType("Course Applications")}
            >
              Contact us
            </RealButton>
          </CentreWrapper>
        </Section>
        <Section id="PrivateTuition">
          <FadeH1>Private Tuition</FadeH1>
          {PrivateTuition.map((data, index) => {
            return (
              <Fade>
                <div className="no-image-data">{data}</div>
              </Fade>
            )
          })}
          <CentreWrapper>
            <RealButton
              type="button"
              data-bs-toggle="modal"
              data-bs-target="#exampleModal1"
              onClick={() => setFormType("Private Tuition - Online")}
            >
              Contact us
            </RealButton>
          </CentreWrapper>
        </Section>
        <Section id="Cultural">
          <FadeH1>Cultural & Etiquette training</FadeH1>
          {Cultural.map((data, index) => {
            return (
              <Fade>
                <div className="no-image-data">{data}</div>
              </Fade>
            )
          })}
          <CentreWrapper>
            <RealButton
              type="button"
              data-bs-toggle="modal"
              data-bs-target="#exampleModal1"
              onClick={() => setFormType("Careers Advice")}
            >
              Contact us
            </RealButton>
          </CentreWrapper>
        </Section>
        <Section id="HolidayCamps">
          <FadeH1>Holiday Camps</FadeH1>
          {HolidayCamp.map((data, index) => {
            return (
              <Fade>
                <div className="no-image-data">{data}</div>
              </Fade>
            )
          })}
          <CentreWrapper>
            <RealButton
              type="button"
              data-bs-toggle="modal"
              data-bs-target="#exampleModal1"
              onClick={() => setFormType("Careers Advice")}
            >
              Contact us
            </RealButton>
          </CentreWrapper>
        </Section>
        <Section id="Familiarisation">
          <FadeH1>Familiarisation Trips</FadeH1>
          {FamiliarisationTrips.map((data, index) => {
            return (
              <Fade>
                <div className="no-image-data">{data}</div>
              </Fade>
            )
          })}
          <InsertedTestimony author="Spanish Agent" quote="The trip was very useful for us to gain a good understanding of the Boarding Schools and the differences between them."/>
          <CentreWrapper>
            <RealButton
              type="button"
              data-bs-toggle="modal"
              data-bs-target="#exampleModal1"
              onClick={() => setFormType("Careers Advice")}
            >
              Contact us
            </RealButton>
          </CentreWrapper>
        </Section>
        <Section id="StudyTours">
          <FadeH1>
            Study Tours ; Exchange programmes ; School twinning programmes
          </FadeH1>
          {StudyTours.map((data, index) => {
            return (
              <Fade>
                <div className="no-image-data">{data}</div>
              </Fade>
            )
          })}
          <CentreWrapper>
            <RealButton
              type="button"
              data-bs-toggle="modal"
              data-bs-target="#exampleModal1"
              onClick={() => setFormType("Careers Advice")}
            >
              Contact us
            </RealButton>
          </CentreWrapper>
        </Section>
        <Section id="InternationalTeacherRecruitment">
          <FadeH1>International teacher recruitment</FadeH1>
          {InternationalTeacherRecruitment.map((data, index) => {
            return (
              <Fade>
                <div className="no-image-data">{data}</div>
              </Fade>
            )
          })}
        </Section>
        <EducationTestimony />
      </Layout>
    </div>
  )
}

export const STUDYLINKS_EDUCATIONSERVICES = graphql`
  query studylinks_educationservices {
    allWpPage(
      filter: {
        title: {
          in: [
            "api-studylinks-education services-Private Tuition"
            "api-studylinks-education services-header"
            "api-studylinks-education services-Holiday Camps"
            "api-studylinks-education services-Applications"
            "api-studylinks-education services-Careers Services"
            "api-studylinks-education services-Familiarisation Trips"
            "api-studylinks-education services-Study Tours; Exchange Programmes; School Twinning Programmes"
            "api-studylinks-education services-International Teacher Recruitment"
            "api-studylinks-support services-Cultural & Etiquette Training"
          ]
        }
      }
      sort: { fields: title, order: ASC }
    ) {
      edges {
        node {
          title
          content
        }
      }
    }
  }
`
